<template>
  <main>
    <section class="section" style="padding-bottom: 0;">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{
            $t('breadcrumb_broker', broker)
          }}</sqr-crumb>
          <sqr-crumb
            name="emails-templates"
            :bid="bid"
            label="breadcrumb_emails_templates"
          />
          <sqr-crumb
            name="emails-template"
            :bid="bid"
            :id="id"
            :label-raw="$t('breadcrumb_emails_template', { id })"
          />
        </ul>
      </nav>
    </section>
    <sqr-progress v-show="loading"/>
    <sqr-error :error="loadError"/>
    <section class="section" v-if="!error && record">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-12-tablet is-7-desktop">
            <div class="field">
              <sqr-input-text label="emails_template_name" :value="record.name" @change="fieldSet({field: 'name', value: $event})"/>
              <div class="help">{{$t('emails_template_help')}}</div>
            </div>
            <div class="field">
              <sqr-input-text label="emails_template_type" :value="record.type" @change="fieldSet({field: 'type', value: $event})"/>
            </div>
            <div class="field">
              <sqr-input-text label="emails_subject" :value="record.subject" @change="fieldSet({field: 'subject', value: $event})"/>
            </div>
            <div class="field">
              <sqr-input-textarea label="emails_body_text" :value="record.text" @change="fieldSet({field: 'text', value: $event})" :rows="20"/>
            </div>
            <div class="field is-pulled-right">
              <sqr-button label="buttons_save" @click="saveAndGo()" :is-loading="saving" color="primary"/>
            </div>
            <sqr-error-note :error="saveError"/>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputTextarea from '@/sqrd/SqrInputTextarea';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'EmailsTemplate',
  components: { SqrInputText, SqrInputTextarea },
  props: {
    bid: String,
    id: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('emailsTemplate', ['record', 'loading', 'saving', 'loadError', 'saveError'])
  },
  mounted () {
    const path = ['brokers', this.bid, 'emails-templates']
    if (this.id) {
      this.sub({path, id: this.id});
    } else {
      this.init({path, record: {type: 'relation'}});
    }
  },
  methods: {
    ...mapMutations('emailsTemplate', ['fieldSet']),
    ...mapActions('emailsTemplate', ['init', 'sub', 'save']),
    async saveAndGo () {
      await this.save();
      this.$router.back();
    }
  }
};
</script>
